import { round } from './math-utils';
import { shopCountryConfigs } from '../config/country-config';

const roundPrice = (
  number: number,
  { brand, regionCode }: { brand: ShopBrand; regionCode: ShopRegionCode }
) => {
  if (shopCountryConfigs[brand][regionCode].decimalPricing) {
    return round(number);
  }

  return Math.round(number);
};

// Calculate the VAT amount from the gross amount and the VAT rate
const taxAmountFromGross = (amount: number, vat: number) =>
  vat > 0 ? amount / (1 + 1 / vat) : 0;

const removeTaxFromGross = (amount: number, vat: number) =>
  amount - amount / (1 + 1 / vat);

// Calculate the gross amount from the net amount and the VAT rate
const taxAmountFromNet = (amount: number, vat: number) =>
  amount * (1 + vat) - amount;

const addTaxToNet = (amount: number, vat: number) =>
  amount + amount * (1 + vat) - amount;

const calculateTaxRate = (priceIncTax: number, priceExTax: number) => {
  if (priceExTax === 0 && priceIncTax === 0) {
    return 0;
  }
  if (priceExTax === 0) {
    throw new Error('Price without tax cannot be zero.');
  }

  const taxRate = (priceIncTax - priceExTax) / priceExTax;
  return parseFloat(taxRate.toFixed(4)); // Returns the tax rate rounded to 4 decimal places. Ex. 25% returns 0.25
};

const formatPrice = (
  price: number | undefined,
  {
    brand,
    regionCode,
    language,
  }: {
    brand: ShopBrand;
    regionCode: ShopRegionCode;
    language: ShopLanguage;
  }
) => {
  if (typeof price === 'undefined') {
    return '';
  }

  const countryConfig = shopCountryConfigs[brand][regionCode];
  const i18n = countryConfig.i18n.find((i) => i.lang === language);

  if (!i18n?.iso) {
    console.warn('No ISO code found for language', language, i18n);
    return `${price}`;
  }

  return new Intl.NumberFormat(i18n.iso, {
    style: 'currency',
    currency: countryConfig.currency.code,
    ...(!countryConfig.decimalPricing
      ? {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      : {}),
  }).format(price);
};

export {
  roundPrice,
  taxAmountFromGross,
  removeTaxFromGross,
  taxAmountFromNet,
  calculateTaxRate,
  addTaxToNet,
  formatPrice,
};
