import { defineRule, configure } from 'vee-validate';
import {
  required,
  email,
  min,
  digits,
  alpha_spaces as alphaSpaces,
  length,
} from '@vee-validate/rules';
import type { Composer } from '@nuxtjs/i18n/dist/runtime/composables';

export default defineNuxtPlugin((nuxtApp) => {
  const i18n = nuxtApp.$i18n as Composer;

  defineRule('required', required);
  defineRule('email', email);
  defineRule('min', min);
  defineRule('digits', digits);
  defineRule('alpha_spaces', alphaSpaces);
  defineRule('length', length);

  defineRule('name', (value: string) => {
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

    if (!value.trim() || !nameRegex.test(value)) {
      return 'Please provide your name';
    }

    return true;
  });

  defineRule('postcode-uk', (value: string) => {
    // NOTE: KW: Kirkwall (Northern Scotland), BT: Northern Ireland, IM: Isle of Man, JE/GY: Channel Islands, PO3/PO4: Isle of Wight
    const postcodeUkRegex =
      /^(?!KW)(?!BT)(?!IM)(?!JE)(?!GY)(?!PO(3[0-9]|4[0-1]))(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z]))))[ ]{0,1}[0-9][A-Z]{2}|(BT80[ ]{0,1}0JG)$/i;

    if (!value.trim() || !postcodeUkRegex.test(value.trim())) {
      return 'Please provide a valid postcode';
    }

    return true;
  });

  defineRule('postcode-sv', (value: string) => {
    const postcodeSeRegex = /^[1-9]{1}[0-9]{2}[ ]{0,1}[0-9]{2}$/;

    if (!value.trim() || !postcodeSeRegex.test(value.trim())) {
      return 'Vänligen ange ett giltigt postnummer';
    }

    return true;
  });

  defineRule('postcode-ca-bc', (value: string) => {
    // Ensures the first letter is "V" (for BC)
    const postcodeBcRegex =
      /^V\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

    if (!value.trim() || !postcodeBcRegex.test(value.trim())) {
      return 'Please provide a valid British Columbia postal code';
    }

    return true;
  });

  defineRule('card_number', (value: string) => {
    const cardNumberRegex = /^(\d{4}\s?){1,4}$/;
    const trimmedValue = value.trim();

    // Basic format validation
    if (!trimmedValue || !cardNumberRegex.test(trimmedValue)) {
      return i18n.t('validation.card_number');
    }

    // Luhn validation
    const cleanValue = trimmedValue.replace(/\s+/g, ''); // Remove spaces
    const cardNumbers: number[] = cleanValue.split('').map(Number);

    // Ensure all elements are valid numbers
    if (cardNumbers.some((num) => Number.isNaN(num))) {
      return i18n.t('validation.card_number'); // Invalid card number
    }

    const checksum = cardNumbers
      .slice(0, -1) // Exclude the check digit
      .reverse() // Reverse the array for easier indexing
      .map((num, idx) => (idx % 2 === 0 ? num * 2 : num)) // Double every second digit
      .map((num) => (num > 9 ? num - 9 : num)) // Subtract 9 from numbers > 9
      .reduce((sum, num) => sum + num, 0); // Sum all the values

    const luhnKey = cardNumbers[cardNumbers.length - 1]; // Last digit
    const isValid = (checksum + luhnKey) % 10 === 0;

    if (!isValid) {
      return i18n.t('validation.card_number'); // Invalid card number
    }

    return true;
  });

  defineRule('card_expiry_date', (value: string) => {
    const cardNumberRegex = /^((0[1-9])|(1[0-2]))\/\d{2}$/;

    if (!value.trim() || !cardNumberRegex.test(value.trim())) {
      return i18n.t('validation.card_expiry_date');
    }

    const [month, year] = value.split('/');
    if (!month || !year || month.length !== 2 || year.length !== 2) {
      return i18n.t('validation.invalid_expiry_format');
    }

    const monthInt = parseInt(month, 10);
    const yearInt = parseInt(`20${year}`, 10); // Convert YY to YYYY
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1; // Month is 0-indexed

    if (monthInt < 1 || monthInt > 12) {
      return i18n.t('validation.invalid_month');
    }

    if (
      yearInt < currentYear ||
      (yearInt === currentYear && monthInt < currentMonth)
    ) {
      return i18n.t('validation.expired_card');
    }

    return true;
  });

  defineRule('card_cvv', (value) => {
    const regex = /^\d{3,4}$/; // Matches exactly 3 or 4 digits
    if (!value || !regex.test(value)) {
      return i18n.t('validation.cvv');
    }
    return true;
  });

  configure({
    generateMessage: (context) => {
      // Show error message for field if it exists
      if (i18n.te(`validation.${context.field}`)) {
        return i18n.t(`validation.${context.field}`);
      }

      // Show error message for rule if it exists
      if (i18n.te(`validation.${context.rule?.name}`)) {
        return i18n.t(`validation.${context.rule?.name}`);
      }

      // Fallback to general error message
      return i18n.t('validation.general');
    },
  });
});
